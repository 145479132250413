import { PDFDocument, layoutMultilineText, rgb } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";
import download from "downloadjs";
import { formatInTimeZone } from "date-fns-tz";
import imageBackground from "/public/zmudriDiplomBackground.jpg";
import fontBoingBoldFile from "/assets/fonts/Boing/Boing-Bold.otf";
import fontDMSansBoldFile from "/assets/fonts/DMSans/DMSans-Bold.ttf";
import fontDMSansRegularFile from "/assets/fonts/DMSans/DMSans-Regular.ttf";

async function _drawBackgroundImage(pdfDoc, page) {
  /* Background image setup */
  const jpgImageBytes = await fetch(imageBackground).then((res) =>
    res.arrayBuffer()
  );
  const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);

  const widthRatio = page.getWidth() / jpgImage.width;
  const heightRatio = page.getHeight() / jpgImage.height;
  const jpgDims = jpgImage.scale(
    widthRatio < heightRatio ? widthRatio : heightRatio
  );

  // Draw a background image
  page.drawImage(jpgImage, {
    x: 0,
    y: 0,
    width: jpgDims.width,
    height: jpgDims.height,
  });
}

async function _drawTexts(pdfDoc, page, certificate) {
  pdfDoc.registerFontkit(fontkit);

  /* Fetch and embed fonts */
  const fontBoingBoldBytes = await fetch(fontBoingBoldFile).then((res) =>
    res.arrayBuffer()
  );
  const fontDMSansBoldBytes = await fetch(fontDMSansBoldFile).then((res) =>
    res.arrayBuffer()
  );
  const fontDMSansRegularBytes = await fetch(fontDMSansRegularFile).then(
    (res) => res.arrayBuffer()
  );
  const fontBoingBold = await pdfDoc.embedFont(fontBoingBoldBytes); // , { subset: true }
  const fontDMSansBold = await pdfDoc.embedFont(fontDMSansBoldBytes); // , { subset: true }
  const fontDMSansRegular = await pdfDoc.embedFont(fontDMSansRegularBytes); // , { subset: true }

  /* Text setup */
  let text = null;
  let multilineText = null;
  let startingPositonY = null;
  let font = null;
  let fontSize = null;
  let textWidth = null;
  let textHeight = null;
  let lineHeight = null;

  const blueThemeColor = rgb(0, 0.4, 1);
  const blackColor = rgb(0, 0, 0);

  // Get the width and height of the page
  const { width, height } = page.getSize();

  // Nadpis
  text = "DIPLOM";
  font = fontBoingBold;
  fontSize = 60;
  textWidth = font.widthOfTextAtSize(text, fontSize);

  page.drawText(text, {
    font: font,
    x: width / 2 - textWidth / 2,
    y: height / 2 + 50,
    size: fontSize,
    color: blueThemeColor,
  });

  // Podnadpis
  text = "za úspešné absolvovanie série:";
  font = fontDMSansRegular;
  fontSize = 16;
  textWidth = font.widthOfTextAtSize(text, fontSize);

  page.drawText(text, {
    font: font,
    x: width / 2 - textWidth / 2,
    y: height / 2,
    size: fontSize,
    color: blueThemeColor,
  });

  text = certificate.path.name;
  fontSize = 16;
  font = fontDMSansBold;
  textHeight = font.heightAtSize(fontSize);
  lineHeight = textHeight + 4;

  // https://github.com/Hopding/pdf-lib/issues/72
  multilineText = layoutMultilineText(text, {
    alignment: "center",
    font: font,
    fontSize: fontSize,
    lineHeight: lineHeight,
    bounds: { width: width - 330, height: height },
  });

  startingPositonY = height / 2 - lineHeight;
  multilineText.lines.forEach((textLine) => {
    const text = textLine.text;
    const textWidth = font.widthOfTextAtSize(text, fontSize);

    page.drawText(text, {
      font: font,
      x: width / 2 - textWidth / 2,
      // x: width / 2,
      y: startingPositonY,
      size: fontSize,
      color: blueThemeColor,
    });

    startingPositonY -= lineHeight;
  });

  // FIXME: This is ugly because of an upstream inconsistency, which should be resolved in the Nuxt 3 refactor.
  text = `${certificate.user.firstName || certificate.user.name} ${
    certificate.user.lastName || certificate.user.surname
  }`;
  fontSize = 16;
  font = fontBoingBold;
  textHeight = font.heightAtSize(fontSize);
  lineHeight = textHeight + 4;

  // https://github.com/Hopding/pdf-lib/issues/72
  multilineText = layoutMultilineText(text, {
    alignment: "center",
    font: font,
    fontSize: fontSize,
    lineHeight: lineHeight,
    bounds: { width: width - 330, height: height },
  });

  startingPositonY = height / 2 - 165 + multilineText.lines.length * lineHeight;
  multilineText.lines.forEach((textLine) => {
    const text = textLine.text;
    const textWidth = font.widthOfTextAtSize(text, fontSize);

    page.drawText(text, {
      font: font,
      x: width / 2 - textWidth / 2,
      // x: width / 2,
      y: startingPositonY,
      size: fontSize,
      color: blackColor,
    });

    startingPositonY -= lineHeight;
  });

  text = formatInTimeZone(
    new Date(certificate.createdAt),
    "Europe/Bratislava",
    "dd.MM.yyyy"
  );
  fontSize = 12;
  font = fontDMSansRegular;
  textWidth = font.widthOfTextAtSize(text, fontSize);

  page.drawText(text, {
    font: font,
    x: 170,
    y: 110,
    size: fontSize,
    color: blackColor,
  });

  text = "www.zmudri.sk";
  fontSize = 16;
  font = fontBoingBold;
  textWidth = font.widthOfTextAtSize(text, fontSize);

  page.drawText(text, {
    font: font,
    x: width / 2 - textWidth / 2,
    y: fontSize * 4,
    size: fontSize,
    color: blueThemeColor,
  });
}

async function downloadCertificate(certificate) {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Add a blank page to the document (default size: A4)
  const page = pdfDoc.addPage();
  // Flip width and height for portrait orientation
  page.setSize(page.getHeight(), page.getWidth());

  await _drawBackgroundImage(pdfDoc, page);
  await _drawTexts(pdfDoc, page, certificate);

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // Trigger the browser to download the PDF document
  download(pdfBytes, "certificate.pdf", "application/pdf");
}

export { downloadCertificate };
